import PropTypes from "prop-types"
import React from "react"

import SectionInner from "../section-inner"
import Section from "../section"

import "./left-right-grid.scss"
import SectionOuter from "../section-outer"
import IconLink from "../icon-link"
import { withTranslation } from "react-i18next"
import PdfIcon from "../../assets/svg/icons/pdf.svg"

const LeftRightGrid = ({ t, items, title, description, backgroundImage, isRound, pdf, children }) => {

  return (
    <Section className="LeftRightGrid Section--vPadding">
      <SectionOuter className="LeftRightGrid-bgImage">
        {
          backgroundImage &&
          <img src={backgroundImage.imageFile ? backgroundImage.imageFile.publicURL : backgroundImage.sourceUrl} alt={backgroundImage.altText}/>
        }
      </SectionOuter>
      <SectionInner className="LeftRightGrid-inner u-applyListStyle">
        <h2 className="Section-title t-h2">{title}</h2>
        <div className="Section-text t-body2 hasLinkAfter" dangerouslySetInnerHTML={{ __html: description }}/>
        {
          pdf &&
          <IconLink absolute className="LeftRightGrid-pdfLink u-responsiveFlexJustify" to={pdf.imageFile ? pdf.imageFile.publicURL : pdf.mediaItemUrl} title={pdf.title}
                    icon={PdfIcon}>
            {t("Mehr Erfahren")}
          </IconLink>
        }
        <div className="LeftRightGrid-items CardGrid">
          {
            children
          }
        </div>
      </SectionInner>
    </Section>

  )
}

LeftRightGrid.propTypes = {
  projects: PropTypes.array,
}

export default withTranslation()(LeftRightGrid)
