import PropTypes from "prop-types"
import React from "react"

import "./ComponentGrid.scss"
import LeftRightCard from "../../components/left-right-card"
import LeftRightGrid from "../../components/left-right-grid/left-right-grid"
import { getImage } from "../../helper"

const ComponentGrid = ({ components, title, description, backgroundImage, isRound, closingParagraph, pdf }) => {

  return (
    <LeftRightGrid title={title} description={description} backgroundImage={backgroundImage}
                   closingParagraph={closingParagraph} pdf={pdf}>
      {
        components.map((componentItem, index) => {
            const data = {
              ...componentItem,
              title: componentItem.title,
              content: componentItem.content,
              link: null,
              image: getImage(componentItem.featuredImage, isRound),
            }
            return (
              <LeftRightCard key={index} title={data.title} content={data.content} link={data.link} image={data.image}
                             variant={index % 2 === 0 ? "left" : "right"}/>
            )
          },
        )
      }
    </LeftRightGrid>
  )
}

ComponentGrid.propTypes = {
  components: PropTypes.array,
}

ComponentGrid.defaultProps = {
  isRound: false,
}

export default ComponentGrid
