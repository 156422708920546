import React from "react"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import Grid from "@material-ui/core/Grid"

import "./PricingTable.scss"

import Section from "../../components/section"
import SectionInner from "../../components/section-inner"
import PricingTableItem from "../../components/pricing-table-item"
import { connect } from "react-redux"
import { setIsUpdate } from "../../state/actions"
import { useTranslation } from "react-i18next"

const PricingTable = ({ title, description, advancedSupportPrice, pricingTableItems, isUpdate, setIsUpdate }) => {

  const { t } = useTranslation()

  const handleIsUpdate = (event, newIsUpdate) => {
    setIsUpdate(newIsUpdate)
  }

  return (
    <Section id="Pricing" className="PricingTable Section--vPadding">
      <SectionInner className="PricingTable-inner u-textCenter">
        {
          title &&
          <h2 className="Section-title t-h2">{title}</h2>
        }
        {
          description &&
          <div className="Section-text t-body2" dangerouslySetInnerHTML={{ __html: description }}/>
        }

        <ToggleButtonGroup
          className="PricingTable-variantToggle"
          value={isUpdate}
          exclusive
          onChange={handleIsUpdate}
        >
          <ToggleButton value={false}>
            {t("Neue Version")}
          </ToggleButton>
          <ToggleButton value={true}>
            {t("Update Trnsys17")}
          </ToggleButton>
        </ToggleButtonGroup>

        <Grid container className="PricingTable-tableGrid" justify="center" spacing={0}>
          {pricingTableItems.map((item, index) => {
            return <PricingTableItem key={index}
                                     itemCount={pricingTableItems.length}
                                     advancedSupportPrice={advancedSupportPrice}
                                     title={item.title}
                                     icon={item.icon ? (item.icon.imageFile ? item.icon.imageFile.publicURL : item.icon.sourceUrl) : null}
                                     listInfos={item.listInfos}
                                     downloadLink={item.downloadLink}
                                     licensePrices={item.licensePrices}
                                     licenseTypes={item.licenseTypes}
                                     licenseInfoText={item.licenseInfoText}
                                     supportTypes={item.supportTypes}
                                     supportInfoText={item.supportInfoText}
                                     isUpdate={isUpdate}
                                     isEducational={item.isEducational}
            />
          })}
        </Grid>
      </SectionInner>
    </Section>
  )
}

const mapStateToProps = (state) => {
  return {
    isUpdate: state.orderInfo.isUpdate,
  }
}

const mapDispatchToProps = {
  setIsUpdate,
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingTable)
