import React from "react"
import classNames from "classnames"

import "./icon-with-circle.scss"

const IconWithCircle = ({ icon, className, inline=true }) => {
  return (
    <div className={classNames("IconWithCircle", className, {inline: 'inline'})}>
      <img className="IconWithCircle-icon" src={icon.imageFile ? icon.imageFile.publicURL : icon.sourceUrl} alt={icon.altText}/>
    </div>
  )
}

export default IconWithCircle

