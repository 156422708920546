import React, { useEffect } from "react"

import "./pricing-table-item.scss"

import IconButton from "@material-ui/core/IconButton"
import InfoIcon from "../../assets/svg/icons/info.svg"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import MenuItem from "@material-ui/core/MenuItem"
import ButtonLink from "../button-link"
import ActionButton from "../action-button"
import Grid from "@material-ui/core/Grid"
import { withTranslation } from "react-i18next"
import { Paper } from "@material-ui/core"
import { useDialog } from "../../hooks/useDialog"
import ProductStepper from "../product-stepper"
import { ORDER_TRNSYS_FIRST, ORDER_TRNSYS_UPDATE } from "../../stepper/orderTypes"
import { setIsEducational, setLicenseAmount, setOrderType, setPriceCalculated } from "../../state/actions"
import { connect } from "react-redux"

const PricingTableItem = ({ t, key, title, icon, downloadLink, listInfos, licensePrices, isUpdate, isEducational, setIsEducational, setLicenseAmount, setOrderType, setPriceCalculated }) => {

  const isFree = !licensePrices || licensePrices.length <= 1

  const inputLabelPrices = React.useRef(null)
  const [licenseAmountLocal, setLicenseAmountLocal] = React.useState(licensePrices && licensePrices.length !== 0 ? licensePrices[0].licenseAmount : 1)
  const [labelWidthPrices, setLabelWidthPrices] = React.useState(0)

  // const inputLabelLicenses = React.useRef(null)
  // const inputLabelSupport = React.useRef(null)
  // const [licenseType, setLicenseType] = React.useState(licenseTypes && licenseTypes.length !== 0 ? licenseTypes[0].licenseType : "")
  // const [support, setSupport] = React.useState(supportTypes && supportTypes.length !== 0 ? supportTypes[0].supportType : "")
  // const [labelWidthLicenses, setLabelWidthLicenses] = React.useState(0)
  // const [labelWidthSupport, setLabelWidthSupport] = React.useState(0)

  const { handleDialogOpen } = useDialog()


  //
  // const licenseStepper = () => {
  //   return <div>
  //     <Stepper activeStep={activeStep} alternativeLabel>
  //       {steps.map(label => (
  //         <Step key={label}>
  //           <StepLabel>{label}</StepLabel>
  //         </Step>
  //       ))}
  //     </Stepper>
  //     <div>
  //       {activeStep === steps.length ? (
  //         <div>
  //           <span>All steps completed</span>
  //           <Button onClick={handleReset}>Reset</Button>
  //         </div>
  //       ) : (
  //         <div>
  //           <span>{getStepContent(activeStep)}</span>
  //           <div>
  //             <Button
  //               disabled={activeStep === 0}
  //               onClick={handleBack}
  //             >
  //               Back
  //             </Button>
  //             <Button variant="contained" color="primary" onClick={handleNext}>
  //               {activeStep === steps.length - 1 ? "Finish" : "Next"}
  //             </Button>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // }

  const divider = isUpdate ? 2 : 1
  let priceCalculated = licensePrices ? (licensePrices.find((option) => option.licenseAmount === licenseAmountLocal).price / divider) : 0

  useEffect(() => {
    if (!isFree) {
      setLabelWidthPrices(inputLabelPrices.current.offsetWidth)
      // setLabelWidthLicenses(inputLabelLicenses.current.offsetWidth)
      // setLabelWidthSupport(inputLabelSupport.current.offsetWidth)
    }
  }, [isFree, setLabelWidthPrices])

// }, [isFree, setLabelWidthPrices, setLabelWidthLicenses, setLabelWidthSupport])

  const handleAmountChange = (event) => {
    event.preventDefault()
    setLicenseAmountLocal(event.target.value)
  }
  //
  // const handleLicenseTypeChange = (event) => {
  //   setLicenseType(event.target.value)
  // }
  //
  // const handleSupportChange = (event) => {
  //   setSupport(event.target.value)
  // }

  const handleActionButtonClick = (event) => {
    event.preventDefault()
    setOrderType(isUpdate ? ORDER_TRNSYS_UPDATE : ORDER_TRNSYS_FIRST)
    setIsEducational(!!isEducational)
    setLicenseAmount(licenseAmountLocal)
    setPriceCalculated(priceCalculated)

    handleDialogOpen(
      t("Bestellung") + ": TRNSYS 18",
      <ProductStepper priceCalculated={priceCalculated} />,
      false
    )
  }

  return (
    <Grid item className="PricingTableItem" key={key} xs={12} lg={6} xl={4}>
      <Paper className="PricingTableItem-paper" elevation={20}>
        <div className="PricingTableItem-top">
          {
            icon &&
            <img src={icon} className="PricingTableItem-image u-marginCenter" alt={icon.altText}/>
          }
          <div className="u-flex u-justifyCenter u-alignCenter">
            <div className="PricingTableItem-title">
              <h3 className="t-h2">{title}</h3>
            </div>

          </div>
          <ul className="PricingTableItem-list">
            {listInfos.map((listInfo, index) => (
              <li key={index}>{listInfo.listInfo}
                {listInfo.listInfoInfoText &&
                <IconButton href={null} className="PricingTableItem-info c-accent-light" size="medium"
                            onClick={() => handleDialogOpen(listInfo.listInfo, listInfo.listInfoInfoText)}>
                  <InfoIcon/>
                </IconButton>
                }
              </li>
            ))}
          </ul>
        </div>
        <div className="PricingTableItem-bottom">
          {
            !isFree &&
            <>
              <div className="PricingTableItem-select">
                <FormControl variant="outlined" className="PricingTableItem-select">
                  <InputLabel ref={inputLabelPrices} htmlFor="outlined-license-simple">
                    {t("Lizenz Menge")}
                  </InputLabel>
                  <Select
                    value={licenseAmountLocal}
                    onChange={handleAmountChange}
                    input={<OutlinedInput labelWidth={labelWidthPrices} name="license" id="outlined-license-simple"/>}
                  >
                    {licensePrices && licensePrices.map((option, index) => (
                      <MenuItem key={index} value={option.licenseAmount}>{option.licenseAmount}</MenuItem>))}
                  </Select>
                </FormControl>

              </div>
              {/*<div className="PricingTableItem-select">*/}

              {/*  <FormControl variant="outlined" className="">*/}
              {/*    <InputLabel ref={inputLabelLicenses} htmlFor="outlined-license-simple">*/}
              {/*      {t("Lizenz Typ")}*/}
              {/*    </InputLabel>*/}
              {/*    <Select*/}
              {/*      value={licenseType}*/}
              {/*      onChange={handleLicenseTypeChange}*/}
              {/*      input={<OutlinedInput labelWidth={labelWidthLicenses} name="license" id="outlined-license-simple"/>}*/}
              {/*    >*/}
              {/*      {licenseTypes && licenseTypes.map((option, index) => (*/}
              {/*        <MenuItem key={index} value={option.licenseType}>{option.licenseType}</MenuItem>))}*/}
              {/*    </Select>*/}
              {/*  </FormControl>*/}

              {/*</div>*/}
              {/*<div className="PricingTableItem-select">*/}

              {/*  <FormControl variant="outlined" className="">*/}
              {/*    <InputLabel ref={inputLabelSupport} htmlFor="outlined-license-simple">*/}
              {/*      Support*/}
              {/*    </InputLabel>*/}
              {/*    <Select*/}
              {/*      value={support}*/}
              {/*      onChange={handleSupportChange}*/}
              {/*      input={<OutlinedInput labelWidth={labelWidthSupport} name="license" id="outlined-license-simple"/>}*/}
              {/*    >*/}
              {/*      {supportTypes && supportTypes.map((option, index) => (*/}
              {/*        <MenuItem key={index} value={option.supportType}>{option.supportType}</MenuItem>))}*/}
              {/*    </Select>*/}
              {/*  </FormControl>*/}

              {/*</div>*/}
            </>
          }

          <p className="PricingTableItem-price t-body1">
            {
              isFree ?
                t("Kostenlos")
                :
                priceCalculated + " €"
            }
          </p>
          {
            isFree ?
              <ButtonLink className="PricingTableItem-actionButton" to={downloadLink ? downloadLink : "/"} title={"Download"} target={"_self"}
                          outlined={true}/>
              :
              <ActionButton className="PricingTableItem-actionButton"
                            variant={"contained"}
                            onClick={handleActionButtonClick}
                            title={t("Bestellen: TRNSYS 18")}>
                {t("Bestellen")}
              </ActionButton>
          }
        </div>
      </Paper>
    </Grid>
  )
}

const mapDispatchToProps = {
  setIsEducational,
  setLicenseAmount,
  setOrderType,
  setPriceCalculated
}

export default  withTranslation()(connect(null, mapDispatchToProps)(PricingTableItem))
