import React from "react"

import "./left-right-card.scss"
import classNames from "classnames"
import IconLink from "../icon-link"
import ArrowRight from "../../assets/svg/icons/arrow-right.svg"
import { withTranslation } from "react-i18next"
import IconWithCircle from "../icon-with-circle/icon-with-circle"
import ActionButton from "../action-button"

const LeftRightCard = ({ t, title, content, image, link, variant, infoItems, actionButton }) => {

  const renderInfoItems = (infoItems) => infoItems.map((infoItem, index) => {
    return <div key={index} className="LeftRightCard-infoItem">
      <IconWithCircle icon={infoItem.icon}/>
      <span className="LeftRightCard-infoItemText">{infoItem.text}</span>
    </div>
  })

  return (
    <section className={classNames("LeftRightCard", variant)}>
      <div className="LeftRightCard-col1 LeftRightCard-info">
        <div className="LeftRightCard-col1Wrapper">
          <h3 className="LeftRightCard-title t-h2">{title}</h3>
          <div className="LeftRightCard-description t-body2" dangerouslySetInnerHTML={{ __html: content }}/>
          {
            infoItems &&
            <div className="LeftRightCard-infoItems">
              {renderInfoItems(infoItems)}
            </div>
          }
          {
            link &&
            <IconLink className="LeftRightCard-action u-indent-left-small" to={link} title={title}
                      target={"_self"} icon={ArrowRight}>
              {t("Mehr Erfahren")}
            </IconLink>
          }
          {
            actionButton &&
            <ActionButton variant={"contained"} className="LeftRightCard-actionButton" title={t("Bestellen")}>{t("Bestellen")}</ActionButton>
          }
        </div>
      </div>
      <div className="LeftRightCard-col2">
        {image}
      </div>

    </section>
  )
}

LeftRightCard.defaultProps = {
  variant: "left",
}


export default withTranslation()(LeftRightCard)
