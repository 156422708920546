import Link from "../link"
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "./button-link.scss"
import Button from "@material-ui/core/Button"

const ButtonLink = ({ to, title, className, outlined, variant }) => {
  variant = outlined ? "outlined" : variant

  return (
      <Button color={"primary"} size={"large"} component={Link} className={classNames("ButtonLink", className)} to={to}
              title={title} variant={variant}>
        {title}
      </Button>
  )
}


ButtonLink.propTypes = {
  to: PropTypes.string,
  outlined: PropTypes.bool,
}

ButtonLink.defaultProps = {
  outlined: false,
}

export default ButtonLink

