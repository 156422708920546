import PropTypes from "prop-types"
import React from "react"

import "./VideoTeaser.scss"

import Section from "../../components/section"
import SectionInner from "../../components/section-inner/section-inner"
import IconLink from "../../components/icon-link"
import PdfIcon from "../../assets/svg/icons/pdf.svg"
import { withTranslation } from "react-i18next"

const VideoTeaser = ({ t, title, pdf, description, video }) => {
  return (
    <Section className="VideoTeaser Section--vPadding">
      <SectionInner className="VideoTeaser-inner u-textCenter">
        {
          title &&
          <h2 className="Section-title t-h2">{title}</h2>
        }
        <div className="Section-text t-body2 hasLinkAfter" dangerouslySetInnerHTML={{ __html: description }}/>
        {
          pdf &&
          <IconLink absolute className="VideoTeaser-link u-responsiveFlexJustify"
                    to={pdf.imageFile ? pdf.imageFile.publicURL : pdf.mediaItemUrl} title={t("Kurzbeschreibung")}
                    icon={PdfIcon}>
            {t("Kurzbeschreibung")}
          </IconLink>
        }
        {
          video &&
          <div className="u-video-container">
            <iframe className="VideoTeaser-video u-video-container"
                    src={"https://www.youtube-nocookie.com/embed/" + video} frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    title="Video"
                    allowFullScreen/>
          </div>
        }

      </SectionInner>
    </Section>
  )
}

VideoTeaser.propTypes = {
  title: PropTypes.string,
}

export default withTranslation()(VideoTeaser)
